body {
    margin: 0;
    padding: 0;
    background-color: #000000;
    font-family: 'Montserrat', sans-serif;
}

/*=== header ===*/
.header-toggle svg {
    fill: #5F996B;
    width: 36px;
    height: 32px;
}

.header_nev {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #000;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

li.sub_nav-itm {
    position: absolute;
    right: 8%;
    top: 0;
    opacity: 0;
    bottom: 0;
    transition: 0.5s;
}

.sub_nav:hover li.sub_nav-itm {
    opacity: 1;
    transition: 0.5s;
}


.header_nev ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.header_nev ul li a,
.header_nev ul li {
    color: #fff;
    font-size: 57px;
    line-height: 112px;
    text-align: center;
    font-weight: 600;
    text-decoration: none;
}

.header_nev ul li:hover a,
.header_nev ul li.active a {
    color: #5f996b;
}

header button {
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
}

.header_nev ul li li.sub_nav-itm a {
    color: #fff;
}

.header_nev ul li li.sub_nav-itm:hover a {
    color: #5f996b;
}

header {
    height: 221px;
}

/*=== section ===*/
.set_title h1 {
    font-size: 57px;
    line-height: 62.7px;
    font-weight: 400;
    color: #FFFFFF;
    font-family: 'Noticia Text', serif;
}


.set_title h1 span {
    color: #55A365;
}

a.contact_redirecr {
    font-size: 13px;
    color: #FFFFFF;
    letter-spacing: 6.5px;
    font-weight: 400;
    text-decoration: underline;
}

.setion_count {
    font-size: 98px;
    color: #55A365;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.setion_count {
    font-size: 98px;
    color: #55A365;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    line-height: 98px;
    position: relative;
    top: -20px;
}

.setion_count span {
    position: relative;
    z-index: 99;
}

.setion_count:after,
.sec_seprator {
    position: absolute;
    width: 0px;
    height: 9px;
    content: "";
    background: #fff;
    left: -72%;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: 3s;
}

.animation_effect .sec_seprator {
    width: 100%;
}

.animation_effect .setion_count:after ,
.animation_effect .home_seprator{
    width: 251px;
    transition: 3s;
}

.sec_seprator {
    left: 0;
    margin: 0;
}

.page_content h6 {
    font-size: 24px;
    line-height: 33.6px;
    font-weight: 400;
    color: #ABABAB;
}

.page_steps:after,
.brand_ex.bran_cali:after {
    position: absolute;
    content: "";
    height: 9px;
    background: #fff;
    width: 0;
    bottom: 0;
    transition: 3s;
}

.animation_effect .page_steps:after,
.animation_effect .brand_ex.bran_cali:after {
    width: 100%;
    transition: 3s;
}

.brand_ex.bran_cali:after {
    top: 0;
}

.page_steps {
    margin-bottom: 37px;
}

.platform_content p {
    font-size: 23px;
    font-weight: 400;
    color: #FFFFFF;
}

.page_steps h2 {
    font-size: 98px;
    line-height: 137.2px;
    font-weight: 600;
    margin: 0;
    color: #55A365;
}

.page_steps p {
    font-size: 22px;
    letter-spacing: 11px;
    font-weight: 600;
    color: #fff;
    text-decoration: underline;
    margin: 0;
}

.brand_ex h3 {
    font-size: 21px;
    font-weight: 400;
    color: #ABABAB;
}

.brand_ex_img img {
    max-width: 345px;
    width: 100%;
}

.page_steps a:hover h2 {
    color: #fff;
}

.set_title.page_title h1 {
    font-size: 98px;
    font-weight: 700;
    color: #55A365;
}

.set_title.page_title h1 {
    font-size: 98px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    line-height: 105px;
}

.setion_count.page_title_count {
    top: 0;
    color: #fff;
    font-weight: 700;
}

.setion_count.page_title_count:after {
    position: absolute;
    width: 0;
    height: 9px;
    content: "";
    background: #fff;
    left: -86%;
    top: 7px;
    bottom: 0;
    margin: auto;
    transition: 3s;
}

.animation_effect .setion_count.page_title_count:after {
    width: 251px;
    transition: 3s;
}

.solver_detail h3 {
    color: #FFFFFF;
    font-size: 22px;
    letter-spacing: 11px;
    font-weight: 400;
    border-bottom: 1px solid #55A365;
    display: inline-block;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.solver_detail h6 {
    color: #fff;
    font-size: 16px;
    letter-spacing: 7px;
    font-weight: 700;
}

.solver_detail p {
    margin: 0;
    font-size: 18px;
    color: #ABABAB;
    font-weight: 400;
}

.adress_tab h5 {
    color: #5F996B;
    font-size: 20px;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 2px;
    margin: 0;
}

.adress_tab a {
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #ababab;
    margin: 0;
}

.adress_tab {
    border-left: 3px solid #5F996B;
    border-right: 3px solid #5F996B;
    padding: 0 15px;
}

.contact_form input,
.contact_form input:active,
.contact_form input:focus,
.contact_form input:hover,
.contact_form textarea,
.contact_form textarea:active,
.contact_form textarea:focus,
.contact_form textarea:hover {
    background: transparent;
    outline: none;
    box-shadow: none;
    border: 0;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
}

.contact_form input::placeholder,
.contact_form textarea::placeholder,
.contact_form .form-floating>label {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
}

button.btn.btn-primary {
    background: #55A365;
    border-color: #55A365;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    height: 55px;
    width: 100%;
    max-width: 200px;
    border-radius: 0;
    transition: 0.3s;
}

button.btn.btn-primary:hover {
    color: #55A365;
    background-color: transparent;
    transition: 0.3s;
}

.platform_content p b,
.platform_content p b a {
    color: #55A365;
}

.platform_content p b a {
    text-decoration: underline;
}

.client_quote p {
    font-size: 22px;
}

.logo h1 {
    font-size: 48px;
    color: #fff;
    margin: 0;
    font-weight: 700;
}

.solutions_p h2 {
    color: #55A365;
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    margin: 0;
}

.platform_items h3 {
    color: #55A365;
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 29px;
}

.platform_items p {
    font-size: 23px;
    color: #fff;
    line-height: 35px;
    font-weight: 400;
    margin: 0;
}

.platform_items ul {
    padding: 0 0 0 40px !important;
    list-style-type: disc !important;
}

.platform_items ul li {
    list-style-type: disc !important;
    font-size: 23px;
    color: #fff;
    line-height: 35px;
    font-weight: 400;
}

.set_title.ram_title h1 {
    color: #55A365;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.marketramp p,
.marketramp ul li {
    font-size: 18px;
    line-height: 25px;
}

.hide_nav,
.nav_active .show_nav {
    display: none;
}

.nav_active .hide_nav {
    display: block;
    position: relative;
    z-index: 9999;
}

@media (max-width:767px) {
    .logo h1 {
        font-size: 27px;
        color: #fff;
        margin: 0;
        font-weight: 700;
    }

    .set_title h1 {
        font-size: 36px;
        line-height: 45px;
    }

    .setion_count,
    .page_steps h2 {
        font-size: 78px;
    }

    .page_content h6,
    .platform_content p,
    .brand_ex h3,
    .client_quote p,
    .platform_items p,
    .platform_items ul li {
        font-size: 16px;
        line-height: 26.6px;
    }

    .set_title.page_title h1 {
        font-size: 71px;
        line-height: 65px;
    }

    header {
        height: 130px;
    }

    .solver_detail h3 {
        font-size: 22px;
        letter-spacing: 4px;
    }

    .page_steps p {
        font-size: 19px;
        line-height: 26.6px;
    }

    .solver_detail h6 {
        letter-spacing: 1px;
    }

    .solver_detail p {
        font-size: 15px;
    }

    .platform_items h3 {
        font-size: 26px;
    }

    .marketramp p,
    .marketramp li {
        font-size: 16px;
        line-height: 23px;
    }
}